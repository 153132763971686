const routes = [
    //首页
    {
        name: 'Home',
        path: '/',
        component: () => import('@/page/Home/index')
    },
     //下载app
     {
        name: 'DownloadApp',
        path: '/DownloadApp',
        component: () => import('@/page/DownloadApp/index')
    },
    {
        name: 'InvestmentPromotionCenter',
        path: '/InvestmentPromotionCenter',
        component: () => import('@/page/InvestmentPromotionCenter/index')
    },
    
];

export default routes
